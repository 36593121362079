<template>
    <div style="min-height:100vh;background-color:#ffffff;position: relative;">
        <!-- <van-nav-bar title="推荐记录" left-text="返回" left-arrow @click-left="onClickLeft" /> -->
        <div class="top_beij">
            <div class="top_beij_bej"></div>
        </div>
        <div class="content">
            <div v-if="list.length">
                <div class="tab_head">
                    <div class="tab_head_hd1 tab_xuhao">序号</div>
                    <div class="tab_head_hd1 tab_name">姓名</div>
                    <div class="tab_head_hd2 tab_qiuzhijindu">
                        <div class="tab_head_hd2_lt">求职进度</div>
                        <div class="tab_head_hd2_rt"></div>
                    </div>
                    <div class="tab_head_hd1 tab_data">入职天数</div>
                </div>
                <div class="tab_cont" v-for="(item,index) in list" :key="index">
                    <div class="tab_cont_h1 tab_xuhao">{{index + 1}}</div>
                    <div class="tab_cont_h1 tab_name">{{item.jobseeker_name}}</div>
                    <div class="tab_cont_h2 tab_qiuzhijindu">{{item.progress}}<div class="tab_cont_h2_rt">{{item.factory_name}}</div></div>
                    <div class="tab_cont_h1 tab_data">{{item.employ_days == '暂无' ? item.employ_days : item.employ_days +'天'}}</div>
                </div>
            </div>
            
            <!-- <table v-if="list.length">
                <tr>
                    <th>序号</th>
                    <th>姓名</th>
                    <th>求职进度</th>
                    <th>入职天数</th>
                </tr>
                <tr v-for="(item,index) in list" :key="index" style="text-align: center;">
                    <td>{{index + 1}}</td>
                    <td>{{item.jobseeker_name}}</td>
                    <td>{{item.progress}}</td>
                    <td>{{item.employ_days == '暂无' ? item.employ_days : item.employ_days +'天'}}</td>
                </tr>
            </table> -->
            <div v-else class="null-data">
                <div>
                    <img :src="src+'null_index.png'" />
                    <div>暂无推荐记录</div>
                </div>
            </div>
            <!-- -->
        <div class="bottom_back">
            <div class="bottom_back_on" @click="gobanck">
                <div class="bottom_back_wenz">返回</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import '@/assets/css/index.css'
import config from "@/utils/config.js";
// import { getIntroduceList } from "@/api/home";
export default {
    data() {
        return {
            list: [],
            src: `${config.ossImageUrl}`,
        }
    },
    mounted() {
        this.$store.commit("getWxSDK");
        this.$store.commit("hideTabbar");
        this.getList()
    },
    methods: {
        gobanck(){
            this.$router.go(-1);
        },
        getList() {
            var data = {
                team_id: localStorage.getItem('team_id')
            }
            this.Request('client/introduce/list', 'get', data).then(res => {
                if (res.status == 0) {
                    this.list = res.data.jobseeker_list
                }
            })
        },
        onClickLeft() {
            this.$router.go(-1)
        },
    }
}
</script>
<style scoped>
.tab_cont{
    width: calc(100% - 70px);
    margin-left: 35px;
    height: 60px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.tab_cont_h1{
    height: 80px;
    line-height: 80px;
    text-align: left;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #1A1A1A;
}
.tab_cont_h2{
    height: 80px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.tab_cont_h2_lt{
    /* width: 115px; */
    height: 80px;
    line-height: 80px;
    text-align: left;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #1A1A1A;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.tab_cont_h2_rt{
    width: calc(100% - 120px);
    /* height: 80px;
    line-height: 80px; */
    text-align: left;
    font-size: 22px;
    margin-left: 5px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.tab_head{
    width: calc(100% - 70px);
    margin-left: 35px;
    padding: 40px 0 20px;
    /* height: 110px; */
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.tab_head_hd1{
    /* height: 110px; */
    /* line-height: 110px; */
    text-align: left;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #0ABBB9;
}
.tab_head_hd2{
    /* height: 110px; */
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.tab_head_hd2_lt{
    width: 115px;
    /* height: 110px; */
    /* line-height: 110px; */
    text-align: left;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #0ABBB9;
}
.tab_head_hd2_rt{
    width: 23px;
    height: 25px;
    background: url('../../assets/img/shuaxuan.png');
    background-size: 100%  100%;
    margin-left: 10px; 
}
.tab_xuhao{
    width: 110px;
}
.tab_name{
    width: 165px;
}
.tab_qiuzhijindu{
    width: calc(100% - 390px);
}
.tab_data{
    width: 115px;
}
.content{
    min-height: 200px;
    width: 100%;
    position: absolute;
    top: 315px;
    z-index: 10;
    background: #ffffff;
    border-radius: 20px 20px 0px 0px;
}
.top_beij{
    width: 100%;
    height: 430px;
    background: #0ABBB9;
}
.top_beij_bej{
    width: 100%;
    height: 430px;
    background: url('../../assets/img/tuijiejilu.png');
    background-size: 100%  100%;  
}
table {
    width: 100%;
    line-height: 50px;
}
th {
    color: #999;
    font-weight: 400;
    font-size: 30px;
}
.null-data {
    display: flex;
    align-items: center;
    padding-top: 2rem;
    color: #666;
}
</style>